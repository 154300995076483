import React from 'react';
import { setCurrency } from 'utils/functionality';

const AddOnsField = ({ data }) => {
  return (
    <div className="add-ons-field">
      <div className="add-ons-field-title">
        <h1>Permintaan Khusus</h1>
      </div>
      {data?.length > 0 ? (
        <div className="add-ons-field-body">
          {data?.map((item, idx) => (
            <div key={idx} className="add-ons-field-body-item">
              <div className="add-ons-field-body-item-image">
                <img src={item?.varieties[0]?.images[0]?.file_name} className="add-ons-field-image" />
              </div>
              <div className="add-ons-field-body-item-info">
                <h6>{item.name}</h6>
                <div className="add-ons-field-body-item-info-variety">
                  <p>
                    Jumlah Sewa : <span className="font-bold">{item?.varieties[0]?.quantity}</span>
                  </p>
                  <p>
                    Warna : <span className="font-bold">{item?.varieties[0]?.color}</span>
                  </p>
                </div>
              </div>
              <div className="add-ons-field-body-item-price">
                <p>{setCurrency(item.unit_price)}</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="add-ons-field-body-empty">
          <p>Tidak Ada Add Ons</p>
        </div>
      )}
    </div>
  );
};

export default AddOnsField;
