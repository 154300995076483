export const column = [
  { header: 'No.', value: 'no' },
  { header: 'Nama Lengkap', value: 'name' },
  { header: 'Jenis Mobil', value: 'car_type' },
  { header: 'Mulai Sewa', value: 'start_date' },
  { header: 'Selesai Sewa', value: 'end_date' },
  { header: 'Jam Sewa', value: 'start_time' },
  { header: 'Total Harga', value: 'total_payment' },
  { header: 'Status Pembayaran', value: 'status' },
];

export const columnExtend = [
  { header: 'No.', value: 'no' },
  { header: 'Nama Lengkap', value: 'name' },
  { header: 'Jenis Mobil', value: 'car_type' },
  { header: 'Mulai Sewa', value: 'start_date' },
  { header: 'Selesai Sewa', value: 'end_date' },
  { header: 'Jam Sewa', value: 'start_time' },
  // { header: 'Total Harga', value: 'total_payment' },
  // { header: 'Status Pembayaran', value: 'status' },
  // { header: 'Status Sewa', value: 'rent_status' },
];

export const refundColumn = [
  { header: 'No.', value: 'no' },
  { header: 'Nama Lengkap', value: 'name' },
  { header: 'Jenis Mobil', value: 'car_type' },
  { header: 'Mulai Sewa', value: 'start_date' },
  { header: 'Selesai Sewa', value: 'end_date' },
  { header: 'Jam Sewa', value: 'start_time' },
  { header: 'Total Harga', value: 'total_payment' },
  { header: 'Status Pengembalian', value: 'status_cancelation' },
];

export const columnReview = [
  { header: 'No.', value: 'no' },
  { header: 'Nama Lengkap', value: 'name' },
  { header: 'Jenis Mobil', value: 'car_type' },
  { header: 'Tanggal Sewa', value: 'start_date' },
];

export const depositColumn = [
  { header: 'No.', value: 'no' },
  { header: 'Nama Lengkap', value: 'name' },
  { header: 'Kode Order', value: 'order_key' },
  { header: 'Jumlah Deposit', value: 'booking_deposit' },
  { header: 'Status', value: 'status' },
];

export const driverTaskColumn = [
  { header: 'No.', value: 'no' },
  { header: 'Nama Driver', value: 'name' },
  { header: 'Job Desk', value: 'job_desk' },
  { header: 'Mulai Sewa', value: 'start_date' },
  { header: 'Selesai Sewa', value: 'end_date' },
  { header: 'Jam Sewa', value: 'start_time' },
];

export const withoutDriverColumn = [
  { header: 'No.', value: 'no' },
  { header: 'Nama Lengkap', value: 'name' },
  { header: 'Jenis Mobil', value: 'car_type' },
  { header: 'Mulai Sewa', value: 'start_date' },
  { header: 'Selesai Sewa', value: 'end_date' },
  { header: 'Jam Sewa', value: 'start_time' },
  { header: 'Status Sewa', value: 'status' },
  { header: 'Status Pembayaran', value: 'type' },
];

export const airportTransferColumn = [
  { header: 'No.', value: 'no' },
  { header: 'Nama Lengkap', value: 'name' },
  { header: 'Kategori Mobil', value: 'package_type' },
  { header: 'Tanggal', value: 'start_date' },
  { header: 'Jam', value: 'start_time' },
  { header: 'Status Pembayaran', value: 'type' },
  { header: 'Status Order', value: 'status' },
];
