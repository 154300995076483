import { useAppContext } from 'components/Context/AppContext';
import { Button, InputField } from 'components/Global';
import SelectFieldCar from 'components/Global/SelectFieldCar';
import SelectFieldDate from 'components/Global/SelectFieldDate';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import SelectFieldShuttle from 'components/Global/SelectFieldShuttle';
import SelectFieldTime from 'components/Global/SelectFieldTime';
import ViolationsList from 'components/Global/ViolationsList';
import { addDays, format, isSameHour, parseISO } from 'date-fns';
import { fetchSummaryOrder } from 'features/orders/actions';
// import { getVehiclesByFilter } from 'features/vehicle/action';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CarChair } from 'icons/car-chair-icon.svg';

// OVERTIME
const overtime = [
  {
    id: 'ot0',
    name: 'Tidak Ada Overtime',
    value: 0,
  },
  {
    id: 'ot1',
    name: '1 Jam',
    value: 1,
  },
  {
    id: 'ot2',
    name: '2 Jam',
    value: 2,
  },
  {
    id: 'ot3',
    name: '3 Jam',
    value: 3,
  },
  {
    id: 'ot4',
    name: '4 Jam',
    value: 4,
  },
  {
    id: 'ot5',
    name: '5 Jam',
    value: 5,
  },
  {
    id: 'ot6',
    name: '6 Jam',
    value: 6,
  },
];

// PAYMENT TYPE
const paymentType = [
  {
    id: 'pyt1',
    name: 'Pembayaran Full',
    value: 'FULL',
  },
  {
    id: 'pyt2',
    name: 'Pembayaran DP',
    value: 'HALF',
  },
];

const WithoutDriverForm = ({
  prevPage,
  nextPage,
  orderData,
  setOrderData,
  vehicleInput,
  setVehicleInput,
  selectedCar,
  setSelectedCar,
  startRentDate,
  setStartRentDate,
  endRentDate,
  setEndRentDate,
  deliveryLocation,
  setDeliveryLocation,
  returnLocation,
  setReturnLocation,
  isOrderConfirmation,
  // refferalCode,
  // setRefferalCode,
  // driver,
  // setDriver,
  allVehicle,
  selectedOvertime,
  setSelectedOvertime,
  selectedPaymentType,
  setSelectedPaymentType,
  orderDataLocalStorage,
  setOrderDataLocalStorage,
  rentalLocationId,
  setRentalLocationId,
}) => {
  const dispatch = useDispatch();

  // CONTEXT
  const { showToast } = useAppContext();

  // FIRST LOAD STATE
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  // MAPPED LOCAL STATE
  const [mappedPaymentType, setMappedPaymentType] = useState(paymentType);

  // GLOBAL STATE
  const { data: dataDelliveryLocation } = useSelector((state) => state.deliveryLocation);
  // const { data: dataVehicle } = useSelector((state) => state.vehicle);
  const { data: violationsData } = useSelector((state) => state.violations);
  // const { data: courierData } = useSelector((state) => state.couriers);
  const { data: rentalLocationData } = useSelector((state) => state.rentalLocation);
  const { data: rentalServicesData } = useSelector((state) => state.services);
  const { data: summaryOrderData } = useSelector((state) => state.summaryOrder);

  // CHANGE FUNCTION
  const changeShuttle = (item, locationName, locationFee) => {
    const newOrderData = {
      ...orderData,
      [locationFee]: item.fee,
      order_detail: { ...orderData.order_detail, [locationName]: item.name },
    };

    if (!item.airport) {
      newOrderData.order_detail.flight_number = '';
      newOrderData.order_detail.landing_time = '';
    }

    setOrderData(newOrderData);

    if (locationName === 'rental_return_location') {
      setReturnLocation(item);
      setOrderDataLocalStorage({ ...orderDataLocalStorage, order_data: newOrderData, returnLocation: item });
    } else {
      setDeliveryLocation(item);
      setOrderDataLocalStorage({ ...orderDataLocalStorage, order_data: newOrderData, deliveryLocation: item });
    }
  };

  const changeInputDetailLocation = (e) => {
    const propsName = e.target.name;
    const newOrderData = {
      ...orderData,
      order_detail: { ...orderData.order_detail, [propsName]: e.target.value },
    };

    setOrderData(newOrderData);
    setOrderDataLocalStorage({ ...orderDataLocalStorage, order_data: newOrderData });
  };

  const changeBookingDate = (date, datePeriod) => {
    if (!date) return;
    setIsFirstLoad(false);

    if (datePeriod === 'start') {
      setStartRentDate(date);
      setEndRentDate('');
      const newOrderData = {
        ...orderData,
        order_detail: {
          ...orderData.order_detail,
          start_booking_date: format(date, 'yyyy-MM-dd').toString(),
          vehicle_id: '',
        },
      };
      setOrderData(newOrderData);
      setSelectedCar(undefined);
      setVehicleInput('');
      setOrderDataLocalStorage({ ...orderDataLocalStorage, order_data: newOrderData, startRentDate: date });
    } else {
      setEndRentDate(date);
      const newOrderData = {
        ...orderData,
        order_detail: {
          ...orderData.order_detail,
          end_booking_date: format(date, 'yyyy-MM-dd').toString(),
          vehicle_id: '',
        },
      };
      setOrderData(newOrderData);
      setSelectedCar(undefined);
      setVehicleInput('');
      setOrderDataLocalStorage({ ...orderDataLocalStorage, order_data: newOrderData, endRentDate: date });
    }
  };

  const changeBookingTime = (hour, minute, hourPeriod) => {
    const newOrderData = {
      ...orderData,
      order_detail: {
        ...orderData.order_detail,
        [hourPeriod]: `${hour}:${minute ? minute : '00'}`,
        end_booking_time: `${hour}:${minute ? minute : '00'}`,
        vehicle_id: '',
      },
    };
    setOrderData(newOrderData);
    setSelectedCar(undefined);
    setVehicleInput('');
    setOrderDataLocalStorage({ ...orderDataLocalStorage, order_data: newOrderData });
  };

  // FUNCTION TO PREVENT SCROLLING ON INPUT NUMBER TYPE
  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current
    // function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  // SCROLL TO TOP WHEN COMPONENT IS LOAD
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getSubserviceIdWithoutDriver = () => {
    const subServiceWithoutDriver = rentalServicesData
      ?.find((item) => item.name === 'Sewa Mobil')
      ?.sub_services?.find((item) => item.name === 'Daily')
      ?.facilities?.find((item) => item.name === 'Without Driver');

    return subServiceWithoutDriver?.id;
  };

  // WILL SET THE END TIME BOOKING PROGRAMMATICALY
  // useEffect(() => {
  //   if (orderData.order_detail.start_booking_time === '') return;
  //   setOrderData({
  //     ...orderData,
  //     order_detail: { ...orderData.order_detail, end_booking_time: '' },
  //   });
  // }, [orderData.order_detail.start_booking_time]);

  // WILL RESET THE END DATE BOOKING WHEN START DATE BOOKING CHANGING
  useEffect(() => {
    if (orderData.order_detail.start_booking_date === '' || isFirstLoad) return;
    setOrderData({
      ...orderData,
      order_detail: { ...orderData.order_detail, end_booking_date: '' },
    });
  }, [orderData.order_detail.start_booking_date]);

  // WILL SET VIOLATIONS WHEN VIOLATIONS GLOBAL STATE CHANGING
  useEffect(() => {
    if (Object.keys(violationsData).length === 0) return;

    const newOrderData = {
      ...orderData,
      order_violations: violationsData.violations.map((item) => {
        return { id: item.id, violation: item.violation, cost: item.cost };
      }),
    };
    setOrderData(newOrderData);
    setOrderDataLocalStorage({ ...orderDataLocalStorage, order_data: newOrderData });
  }, [violationsData]);

  // OVERTIME
  useEffect(() => {
    if (!selectedOvertime) return;

    const overtimeValue = overtime.find((item) => item.id === selectedOvertime).value;
    setOrderData({ ...orderData, over_time: overtimeValue });
  }, [selectedOvertime]);

  // PAYMENT TYPE
  useEffect(() => {
    if (!selectedPaymentType) return;

    const paymentTypeValue = paymentType.find((item) => item.id === selectedPaymentType).value;
    setOrderData({ ...orderData, type: paymentTypeValue });
  }, [selectedPaymentType]);

  useEffect(() => {
    if (
      orderData.order_detail.start_booking_date === '' ||
      orderData.order_detail.start_booking_time === '' ||
      orderData.order_detail.end_booking_date === '' ||
      orderData.order_detail.end_booking_time === '' ||
      !orderData.order_detail.vehicle_id ||
      isOrderConfirmation
    )
      return;

    dispatch(
      fetchSummaryOrder({
        end_booking_date: orderData.order_detail.end_booking_date,
        end_booking_time: orderData.order_detail.end_booking_time,
        start_booking_date: orderData.order_detail.start_booking_date,
        start_booking_time: orderData.order_detail.start_booking_time,
        order_type_id: orderData.order_type_id,
        vehicle_id: orderData.order_detail.vehicle_id,
        without_driver: orderData.order_detail.without_driver ? 1 : 0,
        rental_return_id: dataDelliveryLocation?.shuttle.find(
          (item) => item.name === orderData.order_detail.rental_return_location,
        ).id,
        rental_delivery_id: dataDelliveryLocation?.shuttle.find(
          (item) => item.name === orderData.order_detail.rental_delivery_location,
        ).id,
        location_id: rentalLocationId?.id,
        sub_services_id: getSubserviceIdWithoutDriver(),
      }),
    );
  }, [
    rentalServicesData,
    orderData.order_detail.end_booking_date,
    orderData.order_detail.end_booking_time,
    orderData.order_detail.start_booking_date,
    orderData.order_detail.start_booking_time,
    orderData.order_detail.vehicle_id,
  ]);

  useEffect(() => {
    if (!Object.keys(summaryOrderData).length) return;

    if (summaryOrderData.total_dp > 0) {
      setMappedPaymentType((prev) =>
        prev.map((item) =>
          item.id == 'pyt2'
            ? {
                ...item,
                name: `${
                  item.name.includes('%')
                    ? item.name
                    : summaryOrderData?.formula_percentage?.value
                    ? `${item.name} ${summaryOrderData.formula_percentage.value}%`
                    : item.name
                }`,
              }
            : item,
        ),
      );
    } else if (summaryOrderData.total_dp === 0) {
      setMappedPaymentType((prev) => prev.filter((item) => item.id !== 'pyt2'));
      setSelectedPaymentType('pyt1');
    }
  }, [summaryOrderData]);

  // INTERSECTION FUNCTION OF VEHICLES
  // const intersectionAction = () => {
  //   if (orderData.order_detail.vehicle_id !== '') return;

  //   const payload = {
  //     locationRental: rentalLocationId?.id,
  //     startTrip: `${orderData.order_detail.start_booking_date} ${orderData.order_detail.start_booking_time}`,
  //     endTrip: `${orderData.order_detail.end_booking_date} ${orderData.order_detail.end_booking_time}`,
  //     supportDriver: !orderData.order_detail.without_driver,
  //     customOrder: true,
  //     page: dataVehicle.pagination.page + 1,
  //     limit: 0,
  //   };

  //   // if last page then just return nothing
  //   if (dataVehicle.pagination.page >= dataVehicle.pagination.last_page) return;

  //   // if not last page fetch
  //   dispatch(getVehiclesByFilter(payload));
  // };

  // SUBMIT HANDLER FUNCTION
  const submitHandler = async (e) => {
    e.preventDefault();

    // some validation
    const validStartBookingDate = orderData.order_detail.start_booking_date !== '';
    const validEndBookingDate = orderData.order_detail.end_booking_date !== '';
    const validStartBookingTime = orderData.order_detail.start_booking_time !== '';
    const validEndBookingTime = orderData.order_detail.end_booking_time !== '';
    const validDeliveryLocation = orderData.order_detail.rental_delivery_location !== '';
    const validReturnLocation = orderData.order_detail.rental_return_location !== '';
    const validVehicle = orderData.order_detail.vehicle_id !== '';
    const validDetailDeliveryLocation = orderData.order_detail.rental_delivery_location_detail !== '';
    const validReturnDeliveryLocation = orderData.order_detail.rental_return_location_detail !== '';
    const validFlightNumber = orderData.order_detail.flight_number !== '';
    const validLandingTime = orderData.order_detail.landing_time !== '';
    // const validDriver = driver !== 0;
    const validPaymentType = orderData.type !== '';
    const validDeposit = orderData?.deposit;
    const validDepositEToll = orderData?.deposit_e_toll;
    const validBaggage = isNaN(orderData.order_detail.baggage) || orderData.order_detail.baggage === '';
    const validPassenger = orderData.order_detail.passenger_number;

    // check if all input are valid
    if (!validDeliveryLocation) {
      return showToast({ type: 'error', message: 'Pilih Lokasi Pengantaran' });
    } else if (!validReturnLocation) {
      return showToast({ type: 'error', message: 'Pilih Lokasi Pengembalian' });
    } else if (!validDetailDeliveryLocation) {
      return showToast({ type: 'error', message: 'Masukan Detail Lokasi Pengantaran' });
    } else if (!validReturnDeliveryLocation) {
      return showToast({ type: 'error', message: 'Masukan Detail Lokasi Pengembalian' });
    } else if (!validStartBookingDate) {
      return showToast({ type: 'error', message: 'Pilih Tanggal Mulai' });
    } else if (!validEndBookingDate) {
      return showToast({ type: 'error', message: 'Pilih Tanggal Selesai' });
    } else if (!validStartBookingTime) {
      return showToast({ type: 'error', message: 'Pilih Jam Mulai' });
    } else if (!validEndBookingTime) {
      return showToast({ type: 'error', message: 'Pilih Jam Selesai' });
    } else if (!validVehicle) {
      return showToast({ type: 'error', message: 'Pilih Mobil' });
    } else if (!validPaymentType) {
      return showToast({ type: 'error', message: 'Pilih Tipe Pembayaran' });
    } else if (!validPassenger) {
      return showToast({ type: 'error', message: 'Pilih Jumlah Penumpang' });
    } else if (!validDeposit) {
      return showToast({ type: 'error', message: 'Masukan Deposit' });
    } else if (!validDepositEToll) {
      return showToast({ type: 'error', message: 'Masukan Deposit e-Toll' });
    } else if (validBaggage) {
      return showToast({ type: 'error', message: 'Masukan Banyak Koper yang dibawa' });
    }
    // else if (!validDriver) {
    //   return showToast({ type: 'error', message: 'Pilih Driver' });
    // }

    if (deliveryLocation.airport) {
      if (!validFlightNumber) {
        return showToast({ type: 'error', message: 'Masukan Nomor Penerbangan' });
      } else if (!validLandingTime) {
        return showToast({ type: 'error', message: 'Masukan Jam Landing' });
      }
    }

    const subServiceWithoutDriver = rentalServicesData
      ?.find((item) => item.name === 'Sewa Mobil')
      ?.sub_services?.find((item) => item.name === 'Daily')
      ?.facilities?.find((item) => item.name === 'Without Driver');

    // GET ORDER SUMMARY
    const filter = {
      end_booking_date: orderData.order_detail.end_booking_date,
      end_booking_time: orderData.order_detail.end_booking_time,
      start_booking_date: orderData.order_detail.start_booking_date,
      start_booking_time: orderData.order_detail.start_booking_time,
      order_type_id: orderData.order_type_id,
      vehicle_id: orderData.order_detail.vehicle_id,
      without_driver: orderData.order_detail.without_driver ? 1 : 0,
      rental_return_id: dataDelliveryLocation?.shuttle.find(
        (item) => item.name === orderData.order_detail.rental_return_location,
      ).id,
      rental_delivery_id: dataDelliveryLocation?.shuttle.find(
        (item) => item.name === orderData.order_detail.rental_delivery_location,
      ).id,
      deposit: orderData.deposit,
      deposit_e_toll: orderData.deposit_e_toll,
      violations: orderData.order_violations.reduce((acc, cur) => acc + cur.cost, 0),
      overtime: orderData.over_time,
      location_id: rentalLocationId?.id,
      sub_services_id: subServiceWithoutDriver?.id,
      pasengger_number: orderData.order_detail.passenger_number,
    };

    try {
      const response = await dispatch(fetchSummaryOrder(filter));
      if (response.meta?.requestStatus === 'fulfilled') {
        nextPage();
        setOrderDataLocalStorage({ ...orderDataLocalStorage, page: 3 });
        return;
      }
      showToast({ type: 'error', message: 'Terjadi Kesalahan' });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({ type: 'error', message: 'Terjadi Kesalahan' });
    }
  };

  const startTimeString = orderData.order_detail.start_booking_time || '07:00';
  const endTimeString = orderData.order_detail.end_booking_time || '07:00';
  const startTimeValue = new Date('2000-01-01T' + startTimeString + ':00');
  const endTimeValue = new Date('2000-01-01T' + endTimeString + ':00');

  const isDisabledOvertimeOption = !isSameHour(startTimeValue, endTimeValue);

  useEffect(() => {
    if (isDisabledOvertimeOption) {
      setSelectedOvertime('ot0');
    }
  }, [isDisabledOvertimeOption]);

  return (
    <div className="without-driver-form">
      <SelectFieldShuttle
        label="Lokasi Rental"
        htmlFor="lokasi-rental"
        className="without-driver-form__rental-location"
        data={rentalLocationData}
        value={rentalLocationId.id}
        onChange={setRentalLocationId}
        placeholder="Lokasi Rental"
        searchFeature
        disable={isOrderConfirmation}
      />

      <div className="without-driver-form__form">
        {/* DELIVERY LOCATION */}
        <SelectFieldShuttle
          label="Lokasi Pengantaran"
          htmlFor="lokasi-pengantaran"
          value={deliveryLocation?.id}
          data={dataDelliveryLocation?.shuttle}
          onChange={(item) => changeShuttle(item, 'rental_delivery_location', 'rental_delivery_fee')}
          placeholder="Masukan Lokasi Pengantaran"
          disable={!rentalLocationId.id ? true : isOrderConfirmation ? true : false}
          searchFeature
        />

        {/* RETURN LOCATION */}
        <SelectFieldShuttle
          label="Lokasi Pengembalian"
          htmlFor="lokasi-pengembalian"
          value={returnLocation?.id}
          data={dataDelliveryLocation?.shuttle}
          onChange={(item) => changeShuttle(item, 'rental_return_location', 'rental_return_fee')}
          placeholder="Masukan Lokasi Pengembalian"
          disable={!rentalLocationId.id ? true : isOrderConfirmation ? true : false}
          searchFeature
        />

        {/* DETAIL DELIVERY LOCATION */}
        {!deliveryLocation?.airport ? (
          <InputField
            label="Detail Lokasi Pengantaran"
            htmlFor="detail-lokasi-pengantaran"
            placeholder="Tulis detail lokasi"
            name="rental_delivery_location_detail"
            value={orderData.order_detail.rental_delivery_location_detail}
            onChange={changeInputDetailLocation}
            disable={isOrderConfirmation}
          />
        ) : (
          <div className="without-driver-form__form__jadwal-penerbangan">
            <InputField
              label="No. Penerbangan"
              htmlFor="no-penerbangan"
              placeholder="Tulis No. Penerbangan"
              name="flight_number"
              value={orderData.order_detail.flight_number}
              onChange={changeInputDetailLocation}
              disable={isOrderConfirmation}
            />
            <SelectFieldTime
              label="Jam Landing"
              htmlFor="jam-landing"
              placeholder="00:00"
              value={orderData.order_detail.landing_time}
              onChange={(hour, minute) => {
                const newOrderData = {
                  ...orderData,
                  order_detail: { ...orderData.order_detail, landing_time: `${hour}:${minute ? minute : '00'}` },
                };
                setOrderData(newOrderData);
                setOrderDataLocalStorage({ ...orderDataLocalStorage, order_data: newOrderData });
              }}
              disable={isOrderConfirmation}
            />
            <InputField
              label="Detail Lokasi"
              htmlFor="detail-lokasi-pengantaran"
              placeholder="Tulis detail lokasi"
              name="rental_delivery_location_detail"
              value={orderData.order_detail.rental_delivery_location_detail}
              onChange={changeInputDetailLocation}
              style={{ alignItems: 'start' }}
              disable={isOrderConfirmation}
            />
          </div>
        )}

        {/* DETAIL RETURN LOCATION */}
        <InputField
          label="Detail Lokasi Pengembalian"
          htmlFor="detail-lokasi-pengembalian"
          placeholder="Tulis detail lokasi"
          name="rental_return_location_detail"
          value={orderData.order_detail.rental_return_location_detail}
          onChange={changeInputDetailLocation}
          disable={isOrderConfirmation}
        />

        {/* START DATE & END DATE */}
        <div>
          {isOrderConfirmation ? (
            <SelectFieldDate
              label="Tanggal Mulai"
              name="tanggal-mulai"
              selectedDay={startRentDate}
              value={startRentDate !== '' ? format(startRentDate, 'dd-MM-yyyy') : startRentDate}
              placeholder="Pilih Tanggal"
              disable
            />
          ) : (
            <SelectFieldDate
              label="Tanggal Mulai"
              htmlFor="tanggal-mulai"
              name="tanggal-mulai"
              handleDaySelect={(date) => changeBookingDate(date, 'start')}
              selectedDay={startRentDate}
              value={startRentDate !== '' ? format(new Date(startRentDate), 'dd-MM-yyyy') : startRentDate}
              placeholder="Pilih Tanggal"
              disable={isOrderConfirmation}
            />
          )}

          {isOrderConfirmation ? (
            <SelectFieldDate
              label="Tanggal Selesai"
              name="tanggal-selesai"
              selectedDay={endRentDate}
              fromDate={addDays(startRentDate === '' ? parseISO(startRentDate) : startRentDate, 1)}
              value={endRentDate !== '' ? format(endRentDate, 'dd-MM-yyyy') : endRentDate}
              placeholder="Pilih Tanggal"
              disable
            />
          ) : (
            <SelectFieldDate
              label="Tanggal Selesai"
              htmlFor="tanggal-selesai"
              name="tanggal-selesai"
              handleDaySelect={(date) => changeBookingDate(date, 'end')}
              selectedDay={endRentDate}
              fromDate={addDays(startRentDate === '' ? parseISO(startRentDate) : startRentDate, 1)}
              value={endRentDate !== '' ? format(new Date(endRentDate), 'dd-MM-yyyy') : endRentDate}
              placeholder="Pilih Tanggal"
              disable={isOrderConfirmation ? true : startRentDate === '' ? true : false}
            />
          )}
        </div>

        {/* START TIME & END TIME */}
        <div>
          {isOrderConfirmation ? (
            <SelectFieldTime
              label="Jam Mulai"
              placeholder="00:00"
              value={orderData.order_detail.start_booking_time}
              disable
            />
          ) : (
            <SelectFieldTime
              label="Jam Mulai"
              htmlFor="jam-mulai"
              placeholder="00:00"
              value={orderData.order_detail.start_booking_time}
              onChange={(hour, minute) => changeBookingTime(hour, minute, 'start_booking_time')}
              // choosenDate={startRentDate}
              disable={isOrderConfirmation ? true : startRentDate === '' ? true : false}
              showAllHours={true}
            />
          )}
          {isOrderConfirmation ? (
            <SelectFieldTime
              label="Jam Selesai"
              placeholder="00:00"
              value={orderData.order_detail.end_booking_time}
              disable
              startHour={orderData.order_detail.start_booking_time}
              // showAllHours={true}
              variantHours="WITHOUT_DRIVER"
            />
          ) : (
            <SelectFieldTime
              label="Jam Selesai"
              htmlFor="jam-selesai"
              placeholder="00:00"
              value={orderData.order_detail.end_booking_time}
              onChange={(hour, minute) => changeBookingTime(hour, minute, 'end_booking_time')}
              disable={
                isOrderConfirmation
                  ? true
                  : startRentDate === '' || orderData.order_detail.start_booking_time === ''
                  ? true
                  : false
              }
              startHour={orderData.order_detail.start_booking_time}
              // showAllHours={true}
              variantHours="WITHOUT_DRIVER"
            />
          )}
        </div>

        {/* VEHICLE */}
        <SelectFieldCar
          label="Pilih Mobil"
          htmlFor="pilih-mobil"
          placeholder="Cari Mobil.."
          data={allVehicle}
          selectedCar={selectedCar}
          onSelectCar={(item) => {
            setSelectedCar(item);
            setOrderDataLocalStorage({
              ...orderDataLocalStorage,
              order_data: { ...orderData, order_detail: { ...orderData.order_detail, vehicle_id: item.id } },
              selectedCar: item,
            });
          }}
          value={vehicleInput}
          // intersectionAction={intersectionAction}
          onChange={(e) => setVehicleInput(e.target.value)}
          onClick={(vehicleId, resetVehicle) => {
            if (vehicleId === '') {
              setVehicleInput(resetVehicle);
              return;
            }
            setOrderData({ ...orderData, order_detail: { ...orderData.order_detail, vehicle_id: vehicleId } });
            setVehicleInput(resetVehicle);
          }}
          payloadFilter={{
            locationRental: rentalLocationData.find((item) => item.name === 'BALI')?.id,
            startTrip: `${orderData.order_detail.start_booking_date} ${orderData.order_detail.start_booking_time}`,
            endTrip: `${orderData.order_detail.end_booking_date} ${orderData.order_detail.end_booking_time}`,
            supportDriver: !orderData.order_detail.without_driver,
            customOrder: true,
            page: 0,
            limit: 0,
            subServiceId: getSubserviceIdWithoutDriver(),
          }}
          disable={
            isOrderConfirmation
              ? true
              : orderData.order_detail.start_booking_date === '' ||
                orderData.order_detail.end_booking_date === '' ||
                orderData.order_detail.start_booking_time === '' ||
                orderData.order_detail.end_booking_time === ''
          }
        />

        {/* TIPE PEMBAYARAN */}
        {isOrderConfirmation ? (
          <SelectFieldDropdown
            label="Tipe Pembayaran"
            value={selectedPaymentType}
            data={mappedPaymentType}
            placeholder="Tipe Pembayaran"
            disable
          />
        ) : (
          <SelectFieldDropdown
            label="Tipe Pembayaran"
            htmlFor="tipe-pembayaran"
            value={selectedPaymentType}
            data={mappedPaymentType}
            onChange={(e) => {
              setSelectedPaymentType(e.target.id);
            }}
            placeholder="Tipe Pembayaran"
            disable={
              orderData.order_detail.start_booking_date === '' ||
              orderData.order_detail.end_booking_date === '' ||
              orderData.order_detail.start_booking_time === '' ||
              orderData.order_detail.end_booking_time === '' ||
              orderData.order_detail.vehicle_id === '' ||
              isOrderConfirmation
            }
          />
        )}

        <div>
          {/* OVERTIME */}
          {isOrderConfirmation ? (
            <SelectFieldDropdown
              label="Tambahan Overtime (Jika Ada)"
              value={selectedOvertime}
              data={overtime}
              placeholder={`${orderData.over_time} Jam`}
              disable={isOrderConfirmation || isDisabledOvertimeOption}
            />
          ) : (
            <SelectFieldDropdown
              label="Tambahan Overtime (Jika Ada)"
              htmlFor="tambahan-waktu-overtime"
              value={selectedOvertime}
              data={overtime}
              onChange={(e) => {
                setSelectedOvertime(e.target.id);
              }}
              placeholder="Pilih Overtime"
              disable={isOrderConfirmation || isDisabledOvertimeOption}
            />
          )}

          {/* PASSENGER SEAT */}
          <SelectFieldDropdown
            label="Jumlah Penumpang"
            htmlFor="pilih-jumlah-penumpang"
            placeholder="Pilih Jumlah Penumpang"
            icon={<CarChair />}
            data={
              selectedCar
                ? Array.from({ length: selectedCar?.max_passanger + 1 }, (_, idx) => idx + 1).map((item) => ({
                    id: item,
                    name: `${item} Orang`,
                  }))
                : []
            }
            value={orderData.order_detail.passenger_number}
            onChange={(e) =>
              setOrderData({
                ...orderData,
                order_detail: { ...orderData.order_detail, passenger_number: +e.target.id },
              })
            }
            disable={!selectedCar || isOrderConfirmation}
          />
        </div>

        {/* DEPOSIT & REFERAL CODE */}
        <div>
          <InputField
            type="number"
            label="Deposit (Opsional)"
            htmlFor="deposit"
            placeholder="Rp. 0"
            value={orderData.deposit || ''}
            onChange={(e) => setOrderData({ ...orderData, deposit: parseInt(e.target.value) })}
            disable={isOrderConfirmation}
            onWheel={numberInputOnWheelPreventChange}
          />
          <InputField
            type="number"
            label="Deposit e-Toll"
            htmlFor="deposit-e-toll"
            placeholder="Rp. 0"
            value={orderData.deposit_e_toll || ''}
            onChange={(e) => setOrderData({ ...orderData, deposit_e_toll: parseInt(e.target.value) })}
            disable={isOrderConfirmation}
            onWheel={numberInputOnWheelPreventChange}
          />
          {/* <InputField
        label="Kode Refferal (Opsional)"
        htmlFor="kode-efferal"
        placeholder="Masukan Kode Refferal"
        value={refferalCode}
        onChange={(e) => setRefferalCode(e.target.value)}
        disable={isOrderConfirmation}
      /> */}
        </div>

        <div>
          <InputField label="Kapasitas Koper" value={selectedCar?.max_suitcase || 0} disable />
          <InputField
            type="number"
            label="Koper yang dibawa"
            htmlFor="baggage"
            placeholder="Tulis Jumlah Koper"
            value={orderData.order_detail.baggage}
            onChange={(e) =>
              setOrderData({
                ...orderData,
                order_detail: {
                  ...orderData.order_detail,
                  baggage: isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value),
                },
              })
            }
            disable={isOrderConfirmation}
            onWheel={numberInputOnWheelPreventChange}
          />
        </div>
      </div>

      {/* VIOLATIONS */}
      <span className="without-driver-form__title">
        <h2>Pelanggaran</h2>
      </span>
      <ViolationsList data={orderData.order_violations} disable={isOrderConfirmation} />

      {/* DIVER ASSIGN */}
      {/* <div className="without-driver-form__driver-assign">
        <SelectFieldDropdown
          label="Assign to Driver"
          htmlFor="assign-to-driver"
          data={courierData.data}
          placeholder="Pilih Driver"
          value={driver}
          onChange={(e) => {
            setDriver(e.target.id);
            setOrderDataLocalStorage({ ...orderDataLocalStorage, driver: e.target.id });
          }}
          disable={isOrderConfirmation}
        />
      </div> */}

      {/* ACTION BUTTON */}
      {!isOrderConfirmation && (
        <div className="without-driver-form__button-action">
          <div className="without-driver-form__button-action__action">
            <Button
              width="165px"
              height="39px"
              variant="outline"
              size="md"
              className="button"
              onClick={() => {
                prevPage();
                setOrderDataLocalStorage({ ...orderDataLocalStorage, page: 1 });
              }}
              type="button"
            >
              Kembali
            </Button>
            <Button type="button" width="165px" height="39px" size="md" className="button" onClick={submitHandler}>
              Lanjutkan
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default WithoutDriverForm;
