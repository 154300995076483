import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SidebarHomepage from './HomeComponent/OffCanvas';
import NavbarHomepage from './HomeComponent/NavBar';
import PAGE_ACTIVE from './constant/page-active';
import { resetSelectedVehicle } from 'features/vehicle/vehicleSlice';
import { resetSummaryData } from 'features/orders/summaryOrderSlice';
import NotPermitted from './Global/NotPermitted';
import { setCurrentMenu } from 'features/menu/menuSlice';

const PrivateRoutes = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [selectedPage, setSelectedPage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();

  useEffect(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const activePage = location.pathname.split('/')[1];
    setSelectedPage(PAGE_ACTIVE[`/${activePage}`]);

    if (location.pathname !== '/without-driver/custom-order' && location.pathname !== '/with-driver/custom-order') {
      localStorage.removeItem('order-data');
    }

    if (location.pathname !== 'schedule-price/detail?tab=Schedule Price') {
      dispatch(resetSelectedVehicle());
    }

    if (location.pathname.split('/')[2] !== 'custom-order') {
      dispatch(resetSummaryData());
    }
  }, [location.pathname]);

  useEffect(() => {
    dispatch(setCurrentMenu(selectedPage));
  }, [selectedPage]);

  if (isLoading) return null;

  return auth.isLoggedIn ? (
    <div className="layout">
      <div className="layout-sidebar">
        <SidebarHomepage selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
      </div>
      <div className="layout-content">
        <NavbarHomepage selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
        <main className="content">
          <NotPermitted selectedPage={selectedPage}>
            <Outlet />
          </NotPermitted>
        </main>
      </div>
    </div>
  ) : (
    // <Navigate to="/login" state={{ from: location }} replace />
    <Navigate to="/login" replace />
  );

  // return (
  //   <div className="layout">
  //     <div className="layout-sidebar">
  //       <SidebarHomepage selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
  //     </div>
  //     <div className="layout-content">
  //       <NavbarHomepage selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
  //       <main className="content">
  //         <Outlet />
  //       </main>
  //     </div>
  //   </div>
  // );
};

export default PrivateRoutes;
