import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ReactComponent as CarIcon } from 'icons/car-filled-logo.svg';
import { Button, InputField, TableWrapper, UploadImage } from 'components/Global';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import { indonesianDateFormat } from 'utils/helpers';
import countryCodes from 'utils/country-codes.json';
import clsx from 'clsx';
import { getCustomerData } from 'features/user/slice';
import { fetchOrderById, getDriverTaskDetail, updateDriverTaskStatus } from 'features/orders/actions';
import InputViolation from 'components/Global/InputViolation';
import { getVehicleById } from 'features/vehicle/action';
import PreviewImage from 'components/Modals/PreviewImage';
import { fetchCustomerInfo } from 'features/user/actions';

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES;
const IMAGE_BASE_URL_AWS = process.env.REACT_APP_IMAGES_AWS + '/getandride/order/driver-tasks';

const DetailTaskComponent = () => {
  const { showToast, setShowConfirmation } = useAppContext();
  const vehicleById = useSelector((state) => state.vehicle.selected);
  const data = useSelector((state) => state.driverTask.selected);
  const orderData = useSelector((state) => state.detailOrder.data);
  const [selectedCountry, setSelectedCountry] = useState({
    name: '',
    code: '',
  });
  const [updateStatusData, setUpdateStatusData] = useState({
    status: '',
    note: '',
  });
  // const [totalPayment, setTotalPayment] = useState(0);
  const [showImage, setShowImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [violationList, setViolationList] = useState([]);
  // total price, value that will be added to total payment when updating
  const [totalPrice, setTotalPrice] = useState(0);
  // violation price, value to display total violation price
  const [violationPrice, setViolationPrice] = useState(0);
  const [imageList, setImageList] = useState([]);
  const customer = useSelector(getCustomerData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { taskId } = useParams();

  const findCountryByCode = (code) => countryCodes.find((item) => item.dial_code == code);
  const getFileName = (path = '') => path.split('/').pop();
  const imagePreviewHandler = (image) => {
    setShowImage(true);
    setSelectedImage(image);
  };

  useEffect(() => {
    dispatch(getDriverTaskDetail(taskId));
    dispatch(fetchCustomerInfo(searchParams.get('customerId')));
  }, []);

  useEffect(() => {
    if (!Object.prototype.hasOwnProperty.call(data, 'order')) return;
    if (!taskId) return;
    dispatch(fetchOrderById(data.order.transaction_key));
    dispatch(getVehicleById(data.order.order_detail.vehicle_id));
  }, [data]);

  useEffect(() => {
    if (customer) {
      setSelectedCountry(
        findCountryByCode(orderData?.is_admin_creation ? orderData?.phone_country_code : customer?.phone_code),
      );
    }
  }, [customer, orderData]);

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(data, 'order_violations')) {
      if (data.order_violations !== null) {
        setViolationPrice(() => data?.order_violations?.map((item) => item.cost).reduce((acc, cVal) => acc + cVal, 0));
      }
    }
    if (violationList.length > 0) {
      setTotalPrice(() => violationList.map((item) => item.cost).reduce((acc, cVal) => acc + cVal, 0));
      return;
    }
    setTotalPrice(0);
  }, [violationList, data]);

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(data, 'violations')) {
      if (data?.violations?.length > 0) {
        setViolationList(data.violations);
      }
    }
  }, [data]);

  const handleProcess = async () => {
    const uploadedImage = imageList.map((item) => item.file);

    let nextStatus;

    switch (data?.status) {
      case 'DELIVERY_PROCESS':
        nextStatus = 'PICKUP_PROCESS';
        break;
      case 'PICKUP_PROCESS':
        nextStatus = 'RETURNED';
        break;
      case 'RETURNED':
        nextStatus = 'IN_GARAGE';
        break;
      default:
        throw new Error('task status not found!');
    }

    const payload = {
      status: nextStatus,
      id: data?.id,
      image_captures: uploadedImage,
      note: updateStatusData.note,
      violations: violationList,
    };

    setShowConfirmation({
      message: 'Apakah anda yakin ingin memproses task?',
      show: true,
      onClick: async () => {
        try {
          await dispatch(updateDriverTaskStatus(payload)).unwrap();
          showToast({ type: 'success', message: 'Task Berhasil Diproses' });
        } catch (err) {
          showToast({ type: 'error', message: 'Task Gagal Diproses!' });
        } finally {
          navigate(-1);
        }
      },
    });
  };

  const uploadImageAction = async (image, id) => {
    if (id) {
      setImageList((prev) => prev.filter((item) => item.id !== id));
      return;
    }
    setImageList((prev) => prev.concat(image));
  };

  /**
   * komponen ini untuk merender tombol Proses di modal detail order
   * untuk tabel Konfirmasi dan tabel Belum Diproses saja
   */
  const RenderedProcessButton = () => {
    const showProcessTaskButton = data.status !== 'IN_GARAGE';
    const dataValidation = updateStatusData.note !== '' && imageList.length > 0;

    if (showProcessTaskButton) {
      return (
        <Button
          variant="success"
          className={clsx('button', !dataValidation && 'button--disabled')}
          width={208}
          size="sm"
          disabled={!dataValidation}
          onClick={handleProcess}
        >
          Proses Task
        </Button>
      );
    } else {
      return null;
    }
  };

  const backHandler = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="detail-task">
        <div className="detail-task__back-btn" onClick={backHandler}>
          <LeftArrow />
          <p>Kembali</p>
        </div>
        <TableWrapper icon={<CarIcon fill="#009EF7" width="25px" height="25px" />} title="Driver 1">
          <div className="detail-task__renter-detail">
            <div className="detail-task__renter-detail-title">
              <h1>Detail Penyewa</h1>
            </div>
            <div className="detail-task__renter-detail-body">
              <InputField label="Nama Lengkap" disabled value={orderData?.user_name || '-'} />
              <InputField label="No Order" disabled value={orderData?.order_key || '-'} />

              <div className="detail-task__renter-detail-body__phone-number">
                <InputField
                  label="No Handphone"
                  disabled
                  value={orderData?.is_admin_creation ? orderData?.phone_country_code : customer?.phone_code || '-'}
                  icon={
                    <img
                      src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                      width={26}
                      height={16}
                      alt={selectedCountry?.name + ' nation flag'}
                    />
                  }
                />
                <InputField value={orderData?.phone_number || '-'} disabled />
              </div>

              <InputField label="Plat Nomor" disabled value={vehicleById.license_number ?? '-'} />
              <InputField label="Jumlah Kursi" disabled value={vehicleById.max_passanger ?? '-'} />

              <div className="detail-task__payment-detail-body__preview-image">
                <InputField
                  label="Tipe Mobil"
                  disabled
                  value={vehicleById.name ?? '-'}
                  className="preview-image-input"
                />
                {vehicleById?.photo?.length > 0 ? (
                  <Button
                    bgColor="#D9D9D9"
                    textColor="#000000"
                    className="preview-image-btn"
                    onClick={() => imagePreviewHandler(process.env.REACT_APP_IMAGES + vehicleById.photo[0].name)}
                  >
                    Lihat
                  </Button>
                ) : null}
              </div>
            </div>
          </div>

          <div className="detail-task__rent-detail">
            <div className="detail-task__rent-detail-title">
              <h1>Detail Sewa</h1>
            </div>
            <div className="detail-task__rent-detail-body">
              <InputField
                label="Lokasi Pengantaran"
                disabled
                value={data?.order?.order_detail?.rental_delivery_location || '-'}
              />
              <InputField
                label="Detail Lokasi Pengantaran"
                disabled
                value={data?.order?.order_detail?.rental_delivery_location_detail || '-'}
              />
              {data?.order?.order_detail?.flight_number ? (
                <InputField
                  label="Nomor Penerbangan"
                  disabled
                  value={data?.order?.order_detail?.flight_number || '-'}
                />
              ) : null}
              {data?.order?.order_detail?.landing_time ? (
                <InputField label="Waktu Mendarat" disabled value={data?.order?.order_detail?.landing_time || '-'} />
              ) : null}

              <InputField
                label="Lokasi Pengambilan"
                disabled
                value={data?.order?.order_detail?.rental_return_location || '-'}
              />
              <InputField
                label="Detail Lokasi Pengambilan"
                disabled
                value={data?.order?.order_detail?.rental_return_location_detail || '-'}
              />

              <div className="detail-task__rent-detail-body__rent-date">
                <InputField
                  label="Tanggal Mulai"
                  disabled
                  value={
                    data?.order?.order_detail?.start_booking_date
                      ? indonesianDateFormat(data?.order?.order_detail?.start_booking_date)
                      : '-'
                  }
                />
                <InputField label="Jam Mulai" disabled value={data?.order?.order_detail?.start_booking_time || '-'} />
              </div>

              <div className="detail-task__rent-detail-body__rent-date">
                <InputField
                  label="Tanggal Selesai"
                  disabled
                  value={
                    data?.order?.order_detail?.end_booking_date
                      ? indonesianDateFormat(data?.order?.order_detail?.end_booking_date)
                      : '-'
                  }
                />
                <InputField label="Jam Selesai" disabled value={data?.order?.order_detail?.end_booking_time || '-'} />
              </div>

              <div className="detail-task__rent-detail-body__preview-image">
                <InputField
                  label="KTP"
                  disabled
                  value={
                    orderData?.is_admin_creation && getFileName(data?.order?.order_detail?.identity?.ktp) !== ''
                      ? getFileName(data?.order?.order_detail?.identity?.ktp)
                      : customer?.PersonalInfos?.ktp
                      ? getFileName(customer?.PersonalInfos?.ktp)
                      : 'Belum Upload KTP'
                  }
                  className="preview-image-input"
                />
                {(orderData?.is_admin_creation && getFileName(data?.order?.order_detail?.identity?.ktp) !== '') ||
                customer?.PersonalInfos?.ktp ? (
                  <Button
                    bgColor="#D9D9D9"
                    textColor="#000000"
                    className="preview-image-btn"
                    onClick={() =>
                      imagePreviewHandler(
                        orderData?.is_admin_creation
                          ? data?.order?.order_detail?.identity?.ktp
                          : IMAGE_BASE_URL + customer?.PersonalInfos?.ktp,
                      )
                    }
                  >
                    Lihat
                  </Button>
                ) : null}
              </div>

              <div className="detail-task__rent-detail-body__preview-image">
                <InputField
                  label="SIM"
                  disabled
                  value={
                    orderData?.is_admin_creation && getFileName(data?.order?.order_detail?.identity?.sim) !== ''
                      ? getFileName(data?.order?.order_detail?.identity?.sim)
                      : customer?.PersonalInfos?.sim
                      ? getFileName(customer?.PersonalInfos?.sim)
                      : 'Belum Upload SIM'
                  }
                  className="preview-image-input"
                />
                {(orderData?.is_admin_creation && getFileName(data?.order?.order_detail?.identity?.sim) !== '') ||
                customer?.PersonalInfos?.sim ? (
                  <Button
                    bgColor="#D9D9D9"
                    textColor="#000000"
                    className="preview-image-btn"
                    onClick={() =>
                      imagePreviewHandler(
                        orderData?.is_admin_creation
                          ? data?.order?.order_detail?.identity?.sim
                          : IMAGE_BASE_URL + customer?.PersonalInfos?.sim,
                      )
                    }
                  >
                    Lihat
                  </Button>
                ) : null}
              </div>
            </div>
          </div>

          {(data.status === 'DELIVERY_PROCESS' || data.status === 'IN_GARAGE') && (
            <div className="detail-task__delivery-detail">
              <div className="detail-task__delivery-detail-title">
                <h1>Antar Mobil</h1>
              </div>
              <div className="detail-task__delivery-detail-body">
                {data.status === 'DELIVERY_PROCESS' ? <UploadImage uploadAction={uploadImageAction} /> : null}
                {data?.image_captures?.length > 0
                  ? data?.image_captures
                      ?.filter((item) => item.for_task_status === 'PICKUP_PROCESS')
                      .map((item, idx) => (
                        <div className="detail-task__payment-detail-body__preview-image" key={idx}>
                          <InputField
                            label="Foto Mobil"
                            disabled
                            value={item.file_name}
                            className="preview-image-input"
                          />
                          <Button
                            bgColor="#D9D9D9"
                            textColor="#000000"
                            className="preview-image-btn"
                            onClick={() => imagePreviewHandler(IMAGE_BASE_URL_AWS + '/' + item.file_name)}
                          >
                            Lihat
                          </Button>
                        </div>
                      ))
                  : null}

                <div className="detail-task__delivery-detail-body__notes">
                  <InputField
                    label="Keterangan"
                    onChange={(e) => setUpdateStatusData((prev) => ({ ...prev, note: e.target.value }))}
                    disabled={data.status !== 'DELIVERY_PROCESS'}
                    value={data.status === 'DELIVERY_PROCESS' ? updateStatusData.note : data?.note ?? '-'}
                  />
                </div>
              </div>
            </div>
          )}

          {(data.status === 'PICKUP_PROCESS' || data.status === 'IN_GARAGE') && (
            <div className="detail-task__return-detail">
              <div className="detail-task__return-detail-title">
                <h1>Ambil Mobil</h1>
              </div>
              <div className="detail-task__return-detail-body">
                {data.status === 'PICKUP_PROCESS' ? <UploadImage uploadAction={uploadImageAction} /> : null}
                {data?.image_captures?.length > 0
                  ? data?.image_captures
                      ?.filter((item) => item.for_task_status === 'RETURNED')
                      .map((item, idx) => (
                        <div className="detail-task__payment-detail-body__preview-image" key={idx}>
                          <InputField
                            label="Foto Mobil"
                            disabled
                            value={item.file_name}
                            className="preview-image-input"
                          />
                          <Button
                            bgColor="#D9D9D9"
                            textColor="#000000"
                            className="preview-image-btn"
                            onClick={() => imagePreviewHandler(IMAGE_BASE_URL_AWS + '/' + item.file_name)}
                          >
                            Lihat
                          </Button>
                        </div>
                      ))
                  : null}

                <div className="detail-task__return-detail-body__notes">
                  <InputField
                    label="Keterangan"
                    onChange={(e) => setUpdateStatusData((prev) => ({ ...prev, note: e.target.value }))}
                    disabled={data.status !== 'PICKUP_PROCESS'}
                    value={data.status === 'PICKUP_PROCESS' ? updateStatusData.note : data?.note ?? '-'}
                  />
                </div>

                <div className="detail-task__return-detail-body__input-violation">
                  <InputViolation
                    violationList={violationList}
                    setTotalPrice={setTotalPrice}
                    setViolationList={setViolationList}
                    totalPrice={totalPrice}
                    violationPrice={violationPrice}
                    data={data?.order_violations || []}
                    isOnEdit={data.status === 'PICKUP_PROCESS'}
                  />
                </div>
              </div>
            </div>
          )}

          {(data.status === 'RETURNED' || data.status === 'IN_GARAGE') && (
            <div className="detail-task__garage-detail">
              <div className="detail-task__garage-detail-title">
                <h1>Parkir Ke Garasi</h1>
              </div>
              <div className="detail-task__garage-detail-body">
                {data.status === 'RETURNED' ? <UploadImage uploadAction={uploadImageAction} /> : null}
                {data?.image_captures?.length > 0
                  ? data?.image_captures
                      ?.filter((item) => item.for_task_status === 'IN_GARAGE')
                      .map((item, idx) => (
                        <div className="detail-task__payment-detail-body__preview-image" key={idx}>
                          <InputField
                            label="Foto Mobil"
                            disabled
                            value={item.file_name}
                            className="preview-image-input"
                          />
                          <Button
                            bgColor="#D9D9D9"
                            textColor="#000000"
                            className="preview-image-btn"
                            onClick={() => imagePreviewHandler(IMAGE_BASE_URL_AWS + '/' + item.file_name)}
                          >
                            Lihat
                          </Button>
                        </div>
                      ))
                  : null}

                <div className="detail-task__garage-detail-body__notes">
                  <InputField
                    label="Keterangan"
                    onChange={(e) => setUpdateStatusData((prev) => ({ ...prev, note: e.target.value }))}
                    disabled={data.status !== 'RETURNED'}
                    value={data.status === 'RETURNED' ? updateStatusData.note : data?.note ?? '-'}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="detail-task__transaction-buttons">
            <Button variant="outline" className="button" width={208} size="sm" onClick={backHandler}>
              Kembali
            </Button>
            <RenderedProcessButton />
          </div>
        </TableWrapper>
      </div>
      {showImage &&
        ReactDOM.createPortal(
          <PreviewImage image={selectedImage} setShow={() => setShowImage(false)} />,
          document.getElementById('modal'),
        )}
    </>
  );
};

export default DetailTaskComponent;
