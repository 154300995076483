import React from 'react';
import PreviewImage from 'components/Modals/PreviewImage';
import ReactDOM from 'react-dom';
import InputField from 'components/Global/InputField';
import Button from 'components/Global/Button';

const Thumbnail = ({ image, filename, setState, show, id, handleRemove = () => {} }) => {
  const clickHandler = (e) => {
    setState((prev) =>
      prev.map((item) => {
        if (item.id == e.target.dataset.id) {
          return { ...item, show: true };
        } else {
          return { ...item, show: false };
        }
      }),
    );
  };

  const hideHandler = () => {
    setState((prev) => prev.map((item) => ({ ...item, show: false })));
  };

  const getFileName = (path = '') => path.split('/').pop();

  return (
    <>
      <div className="upload-image__thumbnail__preview-image">
        <InputField label="Foto Mobil" disabled value={getFileName(filename)} className="preview-image-input" />
        <div className="thumbnail__buttons">
          <Button
            bgColor="#D9D9D9"
            textColor="#000000"
            className="preview-image-btn"
            onClick={clickHandler}
            data-id={id}
          >
            Lihat
          </Button>
          <Button
            bgColor="#FF3D3D"
            textColor="#FFFFFF"
            className="remove-image-btn"
            onClick={handleRemove}
            data-id={id}
          >
            Hapus
          </Button>
        </div>
      </div>
      {show &&
        ReactDOM.createPortal(<PreviewImage image={image} setShow={hideHandler} />, document.getElementById('modal'))}
    </>
  );
};

export default Thumbnail;
