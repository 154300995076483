import { Button, InputField } from 'components/Global';
import React, { useState } from 'react';
import { ReactComponent as HidePassword } from 'icons/hide-password.svg';
import { ReactComponent as ShowPassword } from 'icons/show-password.svg';
import { useDispatch, useSelector } from 'react-redux';
import { login } from 'features/auth/actions';
import { useAppContext } from 'components/Context/AppContext';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { createPlayerNotification } from 'features/notification/actions';

const LoginComponent = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { setShowSpinner, showToast } = useAppContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const firebaseToken = useSelector((state) => state.notification.firebaseToken);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!email || !password) return;

    try {
      setShowSpinner(true);
      await dispatch(login({ email, password })).unwrap();

      if (firebaseToken) {
        await dispatch(createPlayerNotification(firebaseToken)).unwrap();
      }

      setEmail('');
      setPassword('');
    } catch (error) {
      showToast({ type: 'error', message: 'Fail to Login' });
    } finally {
      setShowSpinner(false);
    }
    // navigate('/');
  };

  useEffect(() => {
    if (!auth.isLoggedIn) return;
    navigate('/', { replace: true });
  }, [auth.isLoggedIn]);

  return (
    <div className="login__container">
      <header className="login__header">
        <h1>Login</h1>
        <p>Masukkan Email yang terdaftar untuk masuk ke Dashboard</p>
      </header>
      <form className="login__form" onSubmit={submitHandler} autoComplete="off">
        <InputField
          label="Email"
          name="email"
          type="text"
          placeholder={'Masukkan Email'}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <InputField
          icon={showPassword ? <ShowPassword /> : <HidePassword />}
          onIconClick={() => setShowPassword((prev) => !prev)}
          label="Password"
          name="password"
          type={showPassword ? 'text' : 'password'}
          placeholder="Masukan Password anda"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button size="lg" wfull>
          Login
        </Button>
      </form>
    </div>
  );
};

export default LoginComponent;
